import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "../../../../../../environments/runtime-environment";
import { DataService } from "../../../../../services/data.service";
import { GuiService } from "../../../../../services/gui.service";
import { ProductOptionComponent } from "./product-option/product-option.component";
import { ProductType } from "shared-gen/Model/Products/ProductType";
import { Resource } from "shared-gen/Model/Products/Resource";
import { toCurStringWithPrecision } from "shared/payment/currencies";
import { Access } from "../../../../../../shared-gen/Model/Auth/Access";
import { Feature } from "../../../../../../shared-gen/Model/Auth/Feature";
import { LanguageCode } from "../../../../../../shared-gen/Model/Localization/LanguageCode";
import { Option } from "../../../../../../shared-gen/Model/Products/Option";
import { ProductBase } from "../../../../../../shared-gen/Model/Products/ProductBase";
import { EntityType } from "../../../../../../shared-gen/Model/Utils/EntityType";
import { getDefaultID } from "../../../../../../shared/core";
import { Localized } from "../../../../../../shared/localized";
import { OptionFun } from "../../../../../../shared/model/products/options.fun";
import { ProductBaseFun } from "../../../../../../shared/model/products/product-base.fun";
import { ProductFun } from "../../../../../../shared/model/products/product.fun";
import { DependentsFun } from "../../../../../../shared/model/utils/dependents.fun";
import { EntityFun } from "../../../../../../shared/model/utils/entity.fun";
import { TranslationService } from "../../../../../../shared/services/translation.service";
import { decodeEscapeCodesToEditor, encodeEscapeCodesFromEditor } from "../../../../../../shared/utils/text-escape-codes";
import { getDetailsPageRouteForEntityType } from "../../../../../@core/entity-types";
import { User } from "../../../../../@core/interfaces/common/users";
import { UserStore } from "../../../../../@core/stores/user.store";
import { cleanID, isAllowedImageType } from "../../../../../@models/core.fun";
import { hasRights } from "../../../../../auth/iovent/rights";
import { ProductInstanceFun } from "../../../../../../shared/model/products/product-instance.fun";
import { ProductInstance } from "shared-gen/Model/Products/ProductInstance";
import { cloneDeep } from "shared/utils/core-utils";

@Component({
    selector: 'ngx-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.scss']
})
export class ProductComponent extends Localized implements OnInit {
    @ViewChild('fileInputImage') fileInput: ElementRef;

    env = environment;
    user: User;
    validID: boolean = true;

    product: ProductBase;
    previewImage: string | ArrayBuffer;
    image: File;
    availableProductTypes: ProductType[] = [];

    // TODO: global resources setting
    resources: Resource[] = environment().projectName == "tur"
        ? [Resource.BeansLeft, Resource.BeansRight, Resource.Milk, Resource.Chocolate]
        : [Resource.BeansLeft, Resource.BeansRight, Resource.PowderChute, Resource.Chocolate, Resource.Milk, Resource.Milk2, Resource.Milk3]

    currentInstanceIndex = 0;

    isEditable = false;

    get isResource(): boolean { return this.product.ProductType.toString().startsWith('Resource'); }

    constructor(public route: ActivatedRoute,
        private dataService: DataService,
        public router: Router,
        private userStore: UserStore,
        private guiService: GuiService,
        translationService: TranslationService) {
        super(translationService);
    }

    async ngOnInit() {
        this.user = await this.userStore.getUser();

        // May edit?
        this.isEditable = hasRights(Feature.Products, this.userStore.getUser(), Access.Write);

        let id = this.route.snapshot.paramMap.get('id');
        if (id) {
            this.product = await this.dataService.get(id, 'products');
            await this.checkID();
        } else {
            this.product = ProductBaseFun.createNew(this.user.ioventUser.ProviderID);
        }
        this.availableProductTypes = this.determineAvailableProductTypes();

        this.showInstance(0);

        if (this.product.Image) {
            this.previewImage = environment().imageUrl + this.product.Image;
        }

        if (!this.product.LocalizedNames) {
            this.product.LocalizedNames = {};
        }
        if (!this.product.LocalizedDetails) {
            this.product.LocalizedDetails = {};
        }
    }

    navBack() {
        this.router.navigate(['/pages/configuration/products/list']);
    }

    canSave() {
        if (this.product.ID === getDefaultID()) return false;
        if (this.product.Name === '') return false;
        if (!this.validID) return false;
        let check = true;
        if (this.product.Options.length > 0) {
            this.product.Instances.forEach((instance) => {
                if (instance.ID === this.product.ID) {
                    check = false;
                }
            });
        }

        if (this.product.ProductType == ProductType.Resource) {
            this.product.Instances.forEach((instance) => {
                if (!instance.SingleMachineResourceAmount
                    || !instance.DoubleMachineResourceAmount
                    || !instance.MachineResourceFactor
                ) {
                    check = false;
                }
            });
        }

        return check;
    }

    async changeID() {
        if (await this.checkID()) {
            for (let instance of this.product.Instances) {
                if (instance.ID && instance.ID.indexOf('-') !== -1) {
                    instance.ID = instance.ID.replace(this.getBaseIDofInstanceID(instance.ID), this.product.ID);
                } else {
                    instance.ID = this.product.ID;
                }
            }
        }
    }

    async checkID(id: string = null): Promise<boolean> {
        if (id == null) {
            this.product.ID = cleanID(this.product.ID);
            id = this.product.ID;
        } else {
            id = cleanID(id);
        }
        this.validID = EntityFun.isIDValid(id);
        return this.validID;
    }

    getBaseIDofInstanceID(instanceID) {
        if (instanceID) {
            if (instanceID.indexOf('-') !== -1) {
                return instanceID.split('-')[0];
            }
            return instanceID;
        }
    }

    async canDelete() {
        const dependents = await this.dataService.getDependents([this.product.ID], EntityType.ProductBase);
        return false == DependentsFun.hasAny(dependents, this.product.ID);
    }

    async delete() {
        if (await this.canDelete()) {
            await this.guiService.confirmDeleteDialog(async (result) => {
                if (result) {
                    // do confirmation actions
                    try {
                        await this.dataService.delete(this.product.ID, EntityType.ProductBase);
                        this.guiService.showToast(this.translate('Products.Saved'));
                        this.navBack();
                    } catch (e) {
                        this.guiService.showToast(e.message, this.translate('🌐Messages.ErrorWhileSaving'), 'danger');
                    }
                }
            });
        } else {
            this.guiService.showToast(this.translate('Products.InUse')+".", this.translate('Messages.DeleteFailed'), 'danger');
        }
    }

    async save() {
        if (!this.canSave()) return false;

        let notFullySpecifiedInstances = this.product.Instances.filter(inst => !ProductInstanceFun.isValidID(inst.ID));
        if (notFullySpecifiedInstances.length > 0) {
            this.guiService.showErrorMessage(
                this.translate("🌐Products.Instance.CannotSaveChoicesNotFullySpecified") + ": " +
                notFullySpecifiedInstances.map(inst => inst.Name || inst.ID).join(", "),
                null,
                7.5 * 1000 // 7.5 Sekunden
            );
            return false;
        }

        // Check total price steps. Show first illegal price and exit.
        const minStep = environment().totalPriceMinStepCents;
        if (minStep > 0) {
            const currency = this.userStore.getCurrency();
            for (let i = 0; i < this.product.Instances.length; i++) {
                const instance = this.product.Instances[i];
                const totalPrice = instance.Price + instance.Deposit;
                if (totalPrice % minStep != 0) {
                    // Price does not match the defined step. Round down and show message.
                    const wrongPrice = toCurStringWithPrecision(totalPrice / 100, currency, 2);
                    this.guiService.messageDialog(this.translate("🌐General.Price"),
                        this.translateWithTokens("🌐Messages.TotalPriceStepDisallowed",
                            ["%minstep%", minStep, "%wrongprice%", wrongPrice, "%productname%", instance.Name ?? instance.ID]));
                    return;
                }
            }
        }

        const oldID = this.product.ID;
        this.guiService.save<ProductBase>(async result => {
            if (result) {
                try {
                    const savedProduct = result.entity;
                    // When the ID of the saved base product was renamed, rename also the base product IDs of the instances
                    if (savedProduct.ID != oldID) {
                        savedProduct.Instances.forEach(instance => instance.ID =
                            ProductFun.replaceBaseProductID(instance.ID, savedProduct.ID));
                    }
                    this.product = savedProduct;
                    if (this.image)
                        this.product.Image = await this.dataService.saveImageToPool(this.image, 'Product',
                            this.user.ioventUser.ProviderID);

                    await this.dataService.save(this.product, EntityType.ProductBase, result.propagationEnabled);
                    this.guiService.showToast(this.translate('🌐Products.Saved'));
                    if (savedProduct.ID != oldID)
                        this.router.navigate([getDetailsPageRouteForEntityType(EntityType.ProductBase), savedProduct.ID]);

                } catch (e) {
                    this.guiService.showToast(e.message, this.translate('🌐Messages.ErrorWhileSaving'), 'danger');
                }
            }
        }, this.product, EntityType.ProductBase);
    }

    showInstance(index: number) {
        this.currentInstanceIndex = index;
    }

    canAddNewInstance() {
        let alternatives = 1;
        this.product.Options.forEach((opt) => {
            alternatives *= opt.Choices.length;
        });
        return this.product.Instances.length < alternatives;
    }

    addInstance() {
        let instance = ProductFun.createNew(this.product.ID + '-?'.repeat(this.product.Options.length));
        instance.Name = this.product.Name;
        if (this.canAddNewInstance()) {
            this.product.Instances.push(instance);
            let addedInstance = this.product.Instances.length - 1;
            this.showInstance(addedInstance);
        }
    }

    deleteInstance() {
        if (this.product.Instances.length <= 1) return false;
        const currentIndex = this.product.Instances.findIndex(it => {
            return it.ID == this.product.Instances[this.currentInstanceIndex].ID;
        });

        // "Simulates" a gravity effect for the instance tabs. ([instance][deleted]<-[instance] )
        // Instances from the right trickle to the left. The index for the currently opened tab
        // stays the same as long as the deleted instance is not the rightmost.
        const currentLastIndex = this.product.Instances.length - 1;
        const nextIndex = Math.min(currentIndex, currentLastIndex - 1);

        if (!this.product.Instances[nextIndex]) throw new Error(`Can't delete instance[${currentIndex}]!`);

        this.guiService.confirmDeleteDialog(result => {
            if (result) {
                // do confirmation actions
                this.product.Instances.splice(currentIndex, 1);
                this.showInstance(nextIndex);
            }
        }, this.translate('🌐Products.Table.AreYouSureYouWantToDeleteTheProduct')
        );

    }

    fileChanged(event) {
        const file = event.target.files[0];
        if (isAllowedImageType(file.type)) {
            this.image = file;
            const reader = new FileReader();
            reader.onload = e => this.previewImage = reader.result;

            reader.readAsDataURL(file);
        }
    }

    addOption() {
        let option = OptionFun.createNew();
        if (this.product.Options.length <= 0) { // typical first option: size
            option.ID = 'Size';
            option.Name = 'Größe';
            option.Choices = [
                { ID: 'S', Name: `${this.product.Name} S` },
                { ID: 'M', Name: `${this.product.Name} M` },
                { ID: 'L', Name: `${this.product.Name} L` },
            ];
        } else {
            // initial setup with two existing options
            option.Choices = [
                { ID: 'A', Name: `${this.product.Name} A` },
                { ID: 'B', Name: `${this.product.Name} B` },
            ];
        }
        this.showOption(option);
    }

    showOption(option: Option) {
        const index = this.product.Options.findIndex(it => {
            return it.ID === option.ID;
        });
        this.guiService.showDialog(option, ProductOptionComponent, (result) => {
            if (result) {
                if (index !== -1) {
                    this.product.Options[index] = result;
                } else {
                    this.product.Options.push(result);
                }
                // When there is a single "default instance" (= instance with an ID equal to the base ID),
                // and there is one option level now, remove the default instance and replace it by
                // one instance for each option choice
                if (this.product.Instances.length == 1
                    && this.product.Options.length == 1 && this.product.Options[0].Choices.length > 0) {
                    this.product.Instances = this.product.Options[0].Choices.map(choice => {
                        const clonedInitialInstance = cloneDeep(this.product.Instances[0]);
                        const instance: ProductInstance = {
                            ...clonedInitialInstance,
                            ID: `${this.product.ID}-${choice.ID}`,
                            Name: choice.Name || choice.ID,
                        }
                        return instance;
                    });
                    this.showInstance(0);
                }
                this.updateOptionChoices();
                this.showInstance(0);
            }
        }, false, this.product);
    }

    updateOptionChoices() {
        const options = this.product.Options;
        const instances = [...this.product.Instances];

        for(const instance of instances) {
            // get all current choices of this product instance that are encoded in the ID. E.g. SomeProduct-A-B-C -> [A, B, C].
            const currentChoiceIds = instance.ID.split('-');
            currentChoiceIds.shift(); // shift removes the product id from the beginning
            const newChoiceIds = options.map((option, optionIndex) => {
                const currentChoiceId = currentChoiceIds[optionIndex];
                const choiceExists = !!option.Choices.find(choice => choice.ID === currentChoiceId);
                return choiceExists ? currentChoiceId : '?';
            });
            const choicesExists = newChoiceIds.length > 0;
            instance.ID = `${this.product.ID}${choicesExists ? '-' : ''}${newChoiceIds.join('-')}`;
        }
        this.product.Instances = instances;
    }

    deleteOption(option: Option) {

        let instancesUsingOption = this.product.Instances.filter(inst => {
            // find a choice id like "-S" inside the instance id "Coffee-S"
            let foundChoiceIdInInstanceId = option.Choices.find(choice => {
                let choiceSearchPattern = new RegExp(`(-${choice.ID})`, "g");
                let match = inst.ID.match(choiceSearchPattern);
                return match != null;
            });
            return foundChoiceIdInInstanceId != null;
        });
        if (instancesUsingOption.length == 0) {
            this.guiService.confirmDeleteDialog((result) => {
                if (result) {
                    let optionToRemoveIndex = this.product.Options.findIndex(it => {
                        return option.ID === it.ID;
                    });

                    this.product.Options.splice(optionToRemoveIndex, 1);
                    if (this.product.Options.length == 0) {
                        this.product.Instances = [ProductFun.createNew(this.product.ID)];
                        this.currentInstanceIndex = 0;
                        this.product.Instances[this.currentInstanceIndex].Name = this.product.Name;
                    } else {
                        // Remove the corresponding "-?" from the instance ids
                        this.product.Instances.forEach(inst => {
                            // Remove the "-?" that corresponds to the option that
                            // will be deleted.
                            let identifiers = inst.ID.split("-");
                            identifiers.splice(optionToRemoveIndex + 1, 1);
                            inst.ID = identifiers.join("-");
                        });
                    }


                }
            });
        } else {
            this.guiService.showErrorMessage(
                this.translate("🌐Products.Option.ChoiceInUse") + ": "
                + instancesUsingOption.map(inst => inst.Name || inst.ID).join(", ") + ".",
                null,
                10 * 1000 // 10 sekunden
            );
        }
    }

    uploadFileClicked() {
        if (this.fileInput) {
            this.fileInput.nativeElement.click();
        }
    }

    updateNamesInSingleInstance() {
        if (this.product.Instances.length != 1) {
            return;
        }
        this.product.Instances[0].Name = this.product.Name;
        this.product.Instances[0].LocalizedNames = this.product.LocalizedNames;
    }
  

    setName(name: string) {
        if (this.product) {
            // Encode escape codes
            name = encodeEscapeCodesFromEditor(name);

            this.product.Name = name;

            if (this.product.Instances.length == 1 && this.product.Options.length == 0) {
                this.product.Instances[0].Name = this.product.Name;
            }
        }
    }

    changeProductType(productType: ProductType) {
        this.product.ProductType = productType;
    }

    determineAvailableProductTypes(): ProductType[] {
        const allProductTypes = Object.values(ProductType);
        const ret: ProductType[] = allProductTypes.filter(productType => {
            // always keep current product type (even if we don't want to show it like Resources). Otherwise the dropdown will select a different type by accident.
            if (productType === this.product.Type) {
                return true;
            }
            // remove Resources. They shouldn't be a product type and are deprecated
            if (productType.toString().startsWith('Resource')) {
                return false;
            }
            // remove types that portal users should select by themselves or product features we haven't released yet
            if ([ProductType.Unknown, ProductType.Article, ProductType.Charging, ProductType.SandenVendoVM].includes(productType)) {
                return false;
            }
            return true;

        });
        return ret;
    }


}
