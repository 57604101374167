<ng-container *ngIf="product">

    <nb-card status="primary">
        <nb-card-header class="position-relative d-flex justify-content-between align-items-center">

            <span class="nb-card-header-back" (click)="navBack()">
                <nb-icon icon="chevron-left-outline"></nb-icon>
            </span>

            <span>
                <span *ngIf="product.Name">{{product.Name}}</span>
                <span *ngIf="!product.Name">{{"Products.New" | translate:lang}}</span>
            </span>


            <div class="d-flex align-items-center">
                <ng-container *ngIf="isEditable">
                    <!--<div class="icon-card-header-outer cursor-pointer mr-2" (click)="showSettings()"></div>-->
                    <button class="ml-2" nbButton status="success" size="small" (click)="save()"
                        [disabled]="!canSave()">{{"General.Save" | translate:lang}}</button>
                    <button class="ml-2" nbButton ghost status="basic" size="small"
                        (click)="delete()">{{"General.Delete" | translate:lang}}</button>
                </ng-container>
            </div>
        </nb-card-header>

        <nb-card-body>
            <div class="row">

                <div class="col-4">
                    <nb-card class="nb-nested-card">
                        <nb-card-header>{{"Products.Base" | translate:lang}}</nb-card-header>
                        <nb-card-body>
                            <div class="row align-items-center my-3">
                                <div class="col-4">
                                    <label for="prd-id">{{"Products.ID" | translate:lang}}</label>
                                </div>
                                <div class="col-8">
                                    <input id="prd-id" [(ngModel)]="product.ID" name="id" placeholder="ID" nbInput
                                        fullWidth (keyup)="checkID()" (change)="changeID()" class="background--white"
                                        [disabled]="!isEditable"
                                        [status]="isEditable?'primary':'basic'" />
                                    <p *ngIf="!validID" class="error mb-0">{{"Products.ErrorMessage.InvalidId" |
                                        translate:lang}}</p>
                                </div>
                            </div>

                            <div class="row my-3">
                                <div class="col-4 pt-2">
                                    <label for="prd-name">{{"Products.Name" | translate: lang}}</label>
                                </div>
                                <div class="col-8">
                                    <localized-string-input 
                                        inputName="prd-name"
                                        [(localizedString)]="product.LocalizedNames"
                                        [(value)]="product.Name"
                                        (valueChange)="updateNamesInSingleInstance()"
                                        (localizedStringChange)="updateNamesInSingleInstance()"
                                        placeholder="{{('Products.Name' | translate: lang).replace(':','')}}" 
                                        [localizedPlaceholder]="product.Name" 
                                        [disabled]="!isEditable"
                                        [status]="'primary'"
                                    ></localized-string-input>
                                </div>
                            </div>


                            <div class="row align-items-center my-3">
                                <div class="col-4">
                                    <label class="mb-0">{{"🌐ProductType" | translate:lang}}:</label>
                                </div>
                                <div class="col-8">
                                    <nb-select [selected]="product.ProductType"
                                        (selectedChange)="changeProductType($event)"
                                        [status]="isEditable?'primary':'basic'"
                                        [disabled]="!isEditable" fullWidth>
                                        <nb-option *ngFor="let productType of availableProductTypes" [value]="productType">{{("🌐ProductType." +
                                            productType) | translate:lang}}</nb-option>
                                    </nb-select>
                                </div>
                            </div>

                            <!-- Product-image -->
                            <div>
                                <label for="prd-image">{{"Products.Image" | translate:lang}}</label>
                                <nb-card class="nb-double-nested-card">
                                    <nb-card-body>
                                        <div class="row align-items-center">
                                            <div class="col-5">
                                                <div class="preview-image" *ngIf="previewImage"
                                                    [ngStyle]="{'background-image':'url('+previewImage+')'}"></div>
                                            </div>
                                            <div class="col-7">
                                                <ng-container *ngIf="isEditable">
                                                    <input type="file" id="prd-image" (change)="fileChanged($event)"
                                                        #fileInputImage style="display: none;" />
                                                    <button nbButton status="primary" (click)="uploadFileClicked()"
                                                        class="w-100">{{"Products.Image.Choose" |
                                                        translate:lang}}</button>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </nb-card-body>
                                </nb-card>
                            </div>
                            <!--/Product-image -->


                            <nb-card class="nb-double-nested-card">
                                <nb-card-header class="nb-card-header--translateable">
                                    {{"Products.Description" | translate:lang}}
                                    <nb-icon icon="globus" pack="custom-icons" class="translateable-icon"></nb-icon>
                                </nb-card-header>
                                <nb-card-body>
                                    <localized-html-input 
                                        [(localizedHtml)]="product.LocalizedDetails"
                                        [(value)]="product.Details"
                                        [disabled]="!isEditable"
                                    ></localized-html-input>
                                </nb-card-body>
                            </nb-card>


                            <nb-card *ngIf="!product.ProductType.startsWith('Resource')" class="nb-double-nested-card">
                                <nb-card-header class="position-relative">
                                    <span>{{"Products.Options" | translate:lang}}</span>
                                    <div *ngIf="isEditable"
                                        class="icon-card-header-outer icon-card-header-outer--absolute cursor-pointer"
                                        (click)="addOption()">+
                                        <!--<nb-icon pack="custom-icons" icon="bearbeiten"></nb-icon>-->
                                    </div>
                                </nb-card-header>
                                <nb-card-body>
                                    <div *ngIf="product.Options && product.Options.length > 0">

                                        <div *ngFor="let option of product.Options">
                                            <nb-card class="nb-triple-nested-card">
                                                <nb-card-header class="position-relative">
                                                    <span>{{option.Name}}</span>

                                                    <div *ngIf="isEditable"
                                                        class="icon-card-header-outer icon-card-header-outer--absolute icon-card-header-outer--absolute-pos-2 cursor-pointer"
                                                        (click)="showOption(option)">
                                                        <nb-icon pack="custom-icons" icon="bearbeiten"></nb-icon>
                                                    </div>

                                                    <div *ngIf="isEditable"
                                                        class="icon-card-header-outer icon-card-header-outer--absolute cursor-pointer"
                                                        (click)="deleteOption(option)">
                                                        <nb-icon pack="custom-icons" icon="loeschen"></nb-icon>
                                                    </div>

                                                </nb-card-header>
                                                <nb-card-body>
                                                    <ul class="option-list">
                                                        <li *ngFor="let choice of option.Choices">
                                                            {{choice.ID}}
                                                        </li>
                                                    </ul>
                                                </nb-card-body>
                                            </nb-card>

                                        </div>
                                    </div>
                                    <div *ngIf="!product.Options || product.Options.length <=0">
                                        <p>{{"Products.Option.NoChoice" | translate:lang}}</p>
                                    </div>
                                </nb-card-body>
                            </nb-card>
                        </nb-card-body>
                    </nb-card>
                </div>


                <div class="col-8">
                    <nb-card class="nb-nested-card">

                        <nb-card-header class="position-relative d-flex align-items-center"
                            [ngClass]="{'py-0': product.Instances.length > 1}">
                            <span class="mr-5 my-2">
                                {{product.Options.length > 0 ? ('Products.Instance.Instances' |
                                translate:lang) : ('Products.Instance.Details' | translate:lang)}}
                                <span *ngIf="product.Instances.length > 3">({{product.Instances.length}})</span>
                            </span>
                            
                            <div *ngIf="product.Instances.length > 3" class="content-switch switch--inverted ml-5 mr-3 py-2">
                                <nb-button-group>
                                    <button nbButton (click)="showInstance(currentInstanceIndex - 1)" [disabled]="currentInstanceIndex <= 0" status="primary">&lt;</button>
                                    <nb-select [selected]="currentInstanceIndex" [queue]="currentInstanceIndex" (selectedChange)="showInstance($event)" [placeholder]="translate('🌐General.New')">
                                        <nb-option *ngFor="let instance of product.Instances; let i = index" [value]="i">
                                            <span>{{instance.Name || instance.ID || translate('🌐General.New')}}</span>
                                        </nb-option>
                                    </nb-select>
                                    <button nbButton (click)="showInstance(currentInstanceIndex + 1)" [disabled]="currentInstanceIndex + 1 >= product.Instances.length" status="primary">&gt;</button>
                                </nb-button-group>
                            </div>
                            <div *ngIf="product.Instances.length > 1 && product.Instances.length <= 3" class="content-switch switch--inverted ml-5 mr-3">
                                <ul>
                                    <li *ngFor="let instance of product.Instances; let i = index"
                                        (click)="showInstance(i)" [class]="currentInstanceIndex == i ? ' active ' : ''">
                                        {{instance.Name || instance.ID}}
                                    </li>
                                </ul>
                            </div>
                            <div class="icon-card-header-outer icon-card-header-outer--absolute cursor-pointer"
                                (click)="addInstance()" *ngIf="isEditable && canAddNewInstance()">+
                                <nb-icon pack="custom-icons" icon="bearbeiten"></nb-icon>
                            </div>
                        </nb-card-header>
                        <nb-card-body>
                            <ngx-product-instance [productBase]="product"
                                [(productInstance)]="product.Instances[currentInstanceIndex]"
                                [isEditable]="isEditable"></ngx-product-instance>
                        </nb-card-body>
                        <nb-card-footer *ngIf="isEditable" class="d-flex justify-content-end">
                            <button nbButton status="danger" (click)="deleteInstance()"
                                [disabled]="product.Instances.length <=1">{{"General.Delete" | translate:lang}}</button>
                        </nb-card-footer>
                    </nb-card>
                </div>


            </div>
        </nb-card-body>

    </nb-card>

</ng-container>